import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Icon, Table, Popup, Divider, Segment } from 'semantic-ui-react'
import { ErrorLabel } from '@code-yellow/spider'
import Decimal from 'decimal.js'
import { FullWidthTable } from './FormStep'
import styled from 'styled-components'
import { orderBy } from 'lodash'

// components
import { PlayPauseButton } from '../PlayPauseButton'
import TargetDecimalPlusMinusInput from './DecimalPlusMinusInput'
// end components

// helpers
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { humanReadable } from '../../helpers/decimal'
import { formatDuration, DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from 'helpers'
import { isMyWorkstation, isUnknownWorkstation } from './helpers'
import { showNotification } from 'helpers/notification'
// end helpers

// stores
import { Step } from 'store/Step'
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion'
import { ProductionRequest } from 'store/ProductionRequest'
import { Operator } from 'store/Operator'
import { api } from 'store/Base'
// end stores

const DetailInfoSpan = styled.span`
  color: grey;
`

const HeaderCellNowrap = styled(Table.HeaderCell)`
  white-space: nowrap !important;
`

/**
 * Contains the actual table of the material plan to be executed
 */
@observer
export class MaterialPlanTasksTable extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    targetProps: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number.isRequired,
    onPrintProgress: PropTypes.func.isRequired,
    disableTaskPrintButton: PropTypes.bool.isRequired,
    taskIsTimed: PropTypes.bool.isRequired,
    timeLogOverride: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    generalErrors: [],
    getErrors: (bomItemId) => [],
    batchSize: 1,
  }

  @observable showDeletionConfirm = null
  @observable taskProgress = {} // save
  @observable taskWorkInProgress = {}

  constructor(...args) {
    super(...args)

    const { materialPlan, productionRequest, taskIsTimed } = this.props
    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)
    this.setTaskProgress = this.setTaskProgress.bind(this)

    // eslint-disable-next-line no-unused-vars
    for (const item of materialPlan.items.filter(item => item.type === 'task')) {
      const savedDetails = item.details.filter(detail => detail.quantityFinished)
      if (savedDetails.length > 0) {
        this.taskProgress[item.id] = savedDetails.reduce(
          (total, detail) => total.add(detail.quantityFinished ? detail.quantityFinished : Decimal(0)),
          Decimal(0)
        )
        item.taskProgress = this.taskProgress[item.id]
      } else {
        this.taskProgress[item.id] = Decimal(0)
      }
    }

    api.get(`production_order/${productionRequest.productionOrder.id}/working_operator_ids/`).then((res) => {
      this.taskWorkInProgress = res
    })

    if (taskIsTimed) {
      // only show time options if, the formField has materialPlanTaskIsTimed=True AND if feature is enabled
      this.oeeTimedTask = taskIsTimed && isFeatureFlagEnabled('oee_track_operator_time')
    }
  }

  componentDidMount() {
    // if step has been confirmed, force override (so we do not have to fetch)
    this.stopAllTasksReaction = reaction(
      () => this.props.timeLogOverride,
      () => this.taskWorkInProgress = {},
    )
  }

  componentWillUnmount() {
    this.stopAllTasksReaction()
  }

  taskIsInProgressStop(taskId, operatorId) {
    if (this.taskWorkInProgress[taskId]) {
      this.taskWorkInProgress[taskId].remove(operatorId)
    }
  }

  taskIsInProgressStart(taskId, operatorId) {
    if (this.taskWorkInProgress[taskId]) {
      this.taskWorkInProgress[taskId].push(operatorId)
    } else {
      this.taskWorkInProgress[taskId] = [operatorId]
    }
  }


  @computed get batchRequirements() {
    const { materialPlan, batchSize, productionRequest } = this.props
    const batchRequirements = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.filter(item => item.type === 'task')) {
      if (item.requiredQuantity && Decimal(item.requiredQuantity).eq(0)) {
        // Quantity given by ERP is 0, so we calculate it ourselves
        const requiredQuantity = this.getRequiredQuantityForWholeOrder(item, false)

        batchRequirements[item.id] = Decimal(requiredQuantity).div(Decimal(productionRequest.quantity))
      } else if (item.requiredQuantity) {
        batchRequirements[item.id] = Decimal(item.requiredQuantity).div(Decimal(productionRequest.quantity))
      } else {
        batchRequirements[item.id] = Decimal(item.quantityBatch ?? item.quantity)
      }

      batchRequirements[item.id] = batchRequirements[item.id].mul(Decimal(batchSize))
    }

    return batchRequirements
  }

  setTaskProgress(item, val) {
    const { onChange } = this.props
    const min = 0
    const max = this.batchRequirements[item.id]

    if (Decimal(val).lte(Decimal(min))) {
      this.taskProgress[item.id] = Decimal(min)
    } else if (Decimal(val).gte(Decimal(max))) {
      this.taskProgress[item.id] = Decimal(max)
    } else {
      this.taskProgress[item.id] = Decimal(val)
    }
    onChange(item, null, null, this.taskProgress[item.id])
  }

  addItemUnits(item, requiredQuantity, addUnitSuffix = true) {
    const { required } = this.props

    let unit = t('form.duration.minutes')
    if (requiredQuantity.eq(Decimal(0))) {
      unit = ''
    } else if (item.unit?.length > 0) {
      unit = item.unit
    }

    // exact provides step based timing. If required quantity is 0, then get runtime per task from routingSteps
    if (requiredQuantity.eq(Decimal(0)) && !item.shopOrderRoutingStep.isNew) {
      requiredQuantity = Decimal(item.shopOrderRoutingStep.run * required)
    }

    if (!addUnitSuffix) {
      return requiredQuantity
    }

    return `${humanReadable(requiredQuantity, 1)} ${unit}`
  }

  getRequiredQuantityForWholeOrder(item, addUnitSuffix = true) {
    let requiredQuantity = Decimal(0)
    if (item.requiredQuantity) {
      requiredQuantity = Decimal(item.requiredQuantity ?? 0)
    }

    return this.addItemUnits(item, requiredQuantity, addUnitSuffix)
  }

  taskIsInProgress(task) {
    const { operator } = this.props

    // if this operator is working on this item, return true else return false
    const operatorIds = this.taskWorkInProgress[task.id]
    if (!operatorIds) {
      return false
    }
    return operatorIds.includes(operator.id)
  }

  renderMaterialPlan(materialPlanItem) {
    const { getErrors, onPrintProgress, disableTaskPrintButton } = this.props

    const errors = getErrors(materialPlanItem.id)
    const capMax = this.batchRequirements[materialPlanItem.id]

    const isInProgress = this.taskIsInProgress(materialPlanItem)

    return (
      <Table.Row material-plan-item={materialPlanItem.id}>
        <Table.Cell className='compact'>
          <b>{materialPlanItem.number + '. '}</b>
          {materialPlanItem.articleType.code && materialPlanItem.articleType.getLink()}
          {' ' + materialPlanItem.description} <br />
          <DetailInfoSpan>{materialPlanItem.externalWorkStation.name}</DetailInfoSpan>
        </Table.Cell>
        <Table.Cell data-test-required-quantity={materialPlanItem.id}>
          {this.getRequiredQuantityForWholeOrder(materialPlanItem)}
        </Table.Cell>
        <Table.Cell collapsing data-test-done={materialPlanItem.id}>
          {['', null, 'minutes', 'Minutes'].includes(materialPlanItem?.unit) ?
            // if task is timed, we only track time here. So just "check" a task
            <Icon size="big" data-test-finished-checkbox={materialPlanItem.id}
              name={Decimal(this.taskProgress[materialPlanItem.id]).gte(capMax) ? 'check square' : 'square outline'}
              onClick={() => {
                if (Decimal(this.taskProgress[materialPlanItem.id]).lt(capMax) ) {
                  this.setTaskProgress(materialPlanItem, capMax)
                  // stop time if task is finished
                  if (this.oeeTimedTask && isInProgress) {
                    this.stopTime(materialPlanItem)
                  }
                } else {
                  this.setTaskProgress(materialPlanItem, 0)
                }
              }}
            />
          // if task, we want to be able to partially finish stuff, save and only finish when all is done (T44702)
          : <TargetDecimalPlusMinusInput data-test-finished-input={materialPlanItem.id}
            key={`${materialPlanItem.id}-${capMax}`} // Make sure that react-text-mask re-applies props when changing capMax.
            id={materialPlanItem.id}
            capMin={0}
            capMax={capMax}
            target={materialPlanItem}
            setTarget={this.setTaskProgress}
            buttonWidth={4}
            inputWidth={12}
            value={this.taskProgress[materialPlanItem.id]}
            // additional LimitedDecimalInput props
            placeholder={t('workStation.production.performModal.scan.quantity')}
            suffix={`    / ${humanReadable(this.batchRequirements[materialPlanItem.id], 1)}`}
            decimalSymbol="."
            thousandsSeparatorSymbol=","
            decimalLimit={1}
          />}
          {errors.filter((err) => err.code === 'task_not_finished').length > 0 && (
            <ErrorLabel>
              {errors
                .filter((err) => err.code === 'task_not_finished')
                .map(({ message }, i) => (
                  <div key={i}>{message}</div>
                ))}
            </ErrorLabel>
          )}
        </Table.Cell>
        <Table.Cell >
          <Button
            data-test-task-print-button={materialPlanItem.id}
            icon="print"
            disabled={disableTaskPrintButton}
            onClick={() => onPrintProgress(materialPlanItem)}
          />
        </Table.Cell>
        {this.oeeTimedTask && <>
          <Table.Cell collapsing>
            <PlayPauseButton
              isInProgress={isInProgress}
              onPlay={() => this.startTime(materialPlanItem)}
              onPause={() => this.stopTime(materialPlanItem)}
            />
          </Table.Cell>
          {this.renderWorkedTimePopup(materialPlanItem)}
        </>}
      </Table.Row>
    )
  }

  formatLogStartEnd(log) {
    /**
     * Format a log startAt endAt datetime as follows:
     * 1. same date; DATE TIME_START - TIME_END
     * 2. different date: DATETIME_START - DATETIME_END
     */
    const startLog = log.startAt?.clone()
    const endLog = log.endAt?.clone()

    // log without end date
    if (!endLog) {
      return <>{startLog.format(DATE_FORMAT)} <b>{log.startAt.format(TIME_FORMAT)} - ...</b></>
    }
    // log on same date
    if (startLog.startOf('day').isSame(endLog.startOf('day'))) {
      return <>{startLog.format(DATE_FORMAT)} <b>{log.startAt.format(TIME_FORMAT)} - {log.endAt.format(TIME_FORMAT)}</b></>
    }

    // log with different dates
    return `${log.startAt.format(DATETIME_FORMAT)} - ${log.endAt.format(DATETIME_FORMAT)}`
  }

  renderWorkedTimePopup(materialPlanItem) {

    const logTable = <Table data-test-log-table>
      <Table.Header>
        <Table.HeaderCell>{t('operatorTimeLog.field.startAt.label')} - {t('operatorTimeLog.field.endAt.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('operatorTimeLog.field.runtimeSeconds.label')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {orderBy(materialPlanItem?.timeLogs?.models, 'startAt', 'desc').map((log) => (
          <Table.Row>
            <Table.Cell>{this.formatLogStartEnd(log)}</Table.Cell>
            <Table.Cell>{formatDuration(log.runtimeSeconds, { unit: 'second', maxUnit: 'hour' })}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>

    return <Popup hoverable
      wide='very'
      position="left center"
      trigger={<Table.Cell collapsing data-test-operator-time-info={materialPlanItem.id}><Icon name='clock'/></Table.Cell>}
      content={<Segment style={{ overflow: 'auto', maxHeight: '60vh' }}>
        <div data-test-info-header>{t('formStepField.field.materialPlan.startStopTime.workedTime',
          { time: formatDuration(materialPlanItem?.timeLogs?.models?.reduce((sum, log) => sum.add(log.runtimeSeconds), Decimal(0)), { unit: 'second', maxUnit: 'hour' }) }
        )}</div>
        {materialPlanItem?.timeLogs?.length > 0 && <>
          <Divider horizontal/>
          {logTable}
        </>}
      </Segment>}
    />
  }

  async stopTime(materialPlanItem) {
    const { operator, productionRequest } = this.props
    if (!this.oeeTimedTask) {
      return
    }
    const formData = new FormData()
    formData.append('operator_badge_id', operator.badgeId)
    formData.append('production_request_id', productionRequest.id)
    formData.append('task_id', materialPlanItem.id)

    // Stop the time
    this.taskIsInProgressStop(materialPlanItem.id, operator.id)

    await api.post('operator_time_log/operator_time_end/', formData)
    .catch((err) => {
      // on fail, restore state
      this.taskIsInProgressStart(materialPlanItem.id, operator.id)
    })
  }

  async startTime(materialPlanItem) {
    const { operator, productionRequest, step } = this.props

    if (!this.oeeTimedTask) {
      return
    }

    // T48510: if task already finished, warn user
    if (Decimal(this.taskProgress[materialPlanItem.id]).gte(this.batchRequirements[materialPlanItem.id])) {
      showNotification({
        message: t('formStepField.field.materialPlan.startStopTime.taskAlreadyFinished'),
        dismissAfter: 6000,
        error: true,
      })
    }

    const formData = new FormData()
    formData.append('operator_badge_id', operator.badgeId)
    formData.append('production_request_id', productionRequest.id)
    formData.append('task_id', materialPlanItem.id)
    formData.append('step_id', step.id)

    // Start the time
    this.taskIsInProgressStart(materialPlanItem.id, operator.id)

    await api.post('operator_time_log/operator_time_start/', formData)
    .catch((err) => {
      // on fail, restore state
      this.taskIsInProgressStop(materialPlanItem.id, operator.id)
    })
  }

  state = { toggled: false }

  handleClick = (e) => {
    this.setState((prevState) => ({
      toggled: !prevState.toggled,
    }))
  }

  render() {
    const { materialPlan, step, generalErrors } = this.props
    const hasUnlinkedItems =
      materialPlan.items.filter((item) => item.type === 'task' && isUnknownWorkstation(item)).length !== 0
    const hasLinkedItems =
      materialPlan.items.filter((item) => item.type === 'task' && isMyWorkstation(step.workStation.code, item))
        .length !== 0
    let table = []

    //Table for Material Plan Tasks
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>{t('formStepField.field.articleType.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.required.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.finished.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.printLabel.label')}</Table.HeaderCell>
        {this.oeeTimedTask && <>
          <HeaderCellNowrap>{t('formStepField.field.materialPlan.startStopTime.label')}</HeaderCellNowrap>
          <Table.HeaderCell />
        </>}
      </Table.Row>
    )

    table.push(
      <>
        {
          // if there are any linked items, render the header as well
          hasLinkedItems ? (
            <Table.Header>{tableHeader}</Table.Header>
          ) : (
            //else, display a message saying there are no linked tasks/materials
            <Table.Row>
              <Table.Cell colSpan="8">{t('formStepField.field.materialPlan.noTasks')}</Table.Cell>
            </Table.Row>
          )
        }
        <tbody data-material-plan-table="task">
          {materialPlan.items
            .filter((item) => item.type === 'task' && isMyWorkstation(step.workStation.code, item))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => (
              <div key={i}>{message}</div>
            ))}
          </ErrorLabel>
        )}
      </>
    )

    // Extra accordion in case there are any tasks/materials without any linked workstations.
    // Since we don't have a place for these items, we display them on all workstations in an
    // unobstructive ways
    if (hasUnlinkedItems) {
      table.push(
        <>
          <Table.Row>
            <Table.Cell onClick={this.handleClick} colSpan="3" test-accordion-unlinked-items="task">
              {!this.state.toggled && <Icon name="chevron right" />}
              {this.state.toggled && <Icon name="chevron down" />}
              {t('formStepField.field.materialPlan.noWorkstationLinked')}
            </Table.Cell>
          </Table.Row>
          {!hasLinkedItems && this.state.toggled ? tableHeader : null}
          {this.state.toggled && (
            <tbody data-material-plan-unlinked-items-table="task">
              {materialPlan.items
                .filter((item) => item.type === 'task' && isUnknownWorkstation(item))
                .map(this.renderMaterialPlan)}
            </tbody>
          )}
        </>
      )
    }

    return (
      <FullWidthTable padded basic="very">
        {table}
      </FullWidthTable>
    )
  }
}
